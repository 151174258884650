import { type LabelHTMLAttributes, type ReactNode } from 'react'
import { useField } from './form-context'
import { validateThereNotices } from './form-messages'
import { cn } from '@/lib/utils'

export interface FieldLabelProps extends Omit<LabelHTMLAttributes<HTMLLabelElement>, 'label'> {
  children?: ReactNode
  action?: ReactNode
  required?: boolean
}

export const FormLabel = ({ children, className, required: requiredProp, ...props }: FieldLabelProps) => {
  const { id, messages, required } = useField()
  if (children === undefined) return null
  const hasNotices = validateThereNotices(messages)

  const isRequired = requiredProp ?? required

  return (
    <label className={cn([
      'block text-xs font-semibold leading-tight text-primary relative',
      hasNotices && 'text-red-800 dark:text-red-400',
      isRequired && 'after:content-["*"] after:text-red-500 dark:after:text-red-500',
      className
    ])}
      htmlFor={id}
      {...props}
    >
      {children}
    </label>
  )
}
