export const MESSAGES_VALIDATE = Object.freeze({
  REQUIRED: 'El campo es requerido.',
  CHECKBOX: 'Debe seleccionar una opción.',
  MIN: (minValue = 0) => `El valor mínimo es ${minValue}.`,
  MAX: (maxValue = 0) => `El valor máximo es ${maxValue}.`,
  EMAIL: 'El correo electrónico no es válido.',
  URL: 'La URL no es válida.',
  INVALID_DATE: 'La fecha no es válida.',
  INVALID_CHARACTERS: 'El valor contiene caracteres inválidos.'
})

export const MESSAGES_WARNING = Object.freeze({
  ID_EXPIRED: 'El documento de identificación ya expiró.',
  CUI_INVALID: 'El número de CUI no es válido.',
  NIT_INVALID: 'El número de NIT no es válido.'
})
